<template>
  <div class="bigbox">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data() {
    return {
      cdata: {
        dimensions: ['产量', '优品率'],
        source: [
          { product: '供应', 总信息量: 280, 总匹配量: 210, },
          { product: '采购', 总信息量: 290, 总匹配量: 240, },
          { product: '劳务', 总信息量: 230, 总匹配量: 180, },
          { product: '服务', 总信息量: 250, 总匹配量: 190, }
        ],
        xData: ["2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023"],
        data1:[310, 500, 398, 270, 440, 367, 300, 287],
        data2:[22, 37, 29, 16, 23, 25, 20, 23]
      },
      btnIndex: ''
    }
  },
  components: {
    Chart,
  },
  props: ['current'],
  watch: {
    current(val) {
      this.btnIndex = val
      console.log(this.btnIndex);
      if(this.btnIndex==0){
        this.cdata.data1=[310, 500, 398, 270, 440, 367, 300, 287]
        this.cdata.data2=[22, 37, 29, 16, 23, 25, 20, 23]
      }else if(this.btnIndex==1){
        this.cdata.data1=[210, 300, 478, 350, 440, 290, 340, 381]
        this.cdata.data2=[25, 32, 34, 23, 35, 21, 28, 39]
      }else if(this.btnIndex==2){
        this.cdata.data1=[110, 238, 281, 483, 374, 482, 287, 364]
        this.cdata.data2=[27, 23, 32, 21, 22, 16, 23, 34]
      }else if(this.btnIndex==3){
        this.cdata.data1=[123, 233, 274, 253, 500, 472, 421, 382]
        this.cdata.data2=[26, 33, 22, 24, 34, 28, 27, 19]
      }
      else if(this.btnIndex==4){
        this.cdata.data1=[233, 483, 382, 278, 440, 242, 422, 231]
        this.cdata.data2=[25, 32, 34, 23, 35, 21, 28, 39]
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.bigbox {
  margin-top: 10px;
}
</style>