<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">温室概览/产量分析</span>
    </div>
    <div class="content">
      <div class="top">
        <div class="top-item" v-for="(item, index) in data" :key="index">
          <img :src="item.url" alt="" class="top-item-img">
          <span class="top-item-text">{{ item.text }}</span>
          <span class="top-item-num">{{ item.num }}</span>
          <span class="top-item-unit">{{ item.unit }}</span>
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <button class="btn" v-for="(item,index) in btnList" :key="index" :class="{active: index ===  current}" @click="change(index)">{{ item.text }}</button>
        </div>
        <div class="right">
          <bottomChart :current="current"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bottomChart from '@/components/echart/page3/bottomChart'
export default {
  components:{
    bottomChart
  },
  data() {
    return {
      data: [
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon11.png',
          text: '种植面积',
          num: '357',
          unit: 'm²'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon12.png',
          text: '耗电量',
          num: '342',
          unit: 'W'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon13.png',
          text: '灌溉量',
          num: '1537',
          unit: 'L'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/right-bottom-icon14.png',
          text: '预计产能',
          num: '3155',
          unit: 'T'
        },
      ],
      btnList:[
        {
          text:'西红柿'
        },
        {
          text:'黄瓜'
        },
        {
          text:'草莓'
        },
        {
          text:'茄子'
        },
      ],
      current:0
    }
  },
  mounted() {
    
  },
  methods: {
    change(index){
		this.current=index;
	}
  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 676px;
  margin: 0px 33px 10px 33px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title2.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .content {
    .top {
      width: 100%;
      display: flex;
      margin-top: 21px;

      .top-item {
        width: 25%;
        display: flex;
        align-items: center;

        .top-item-img {
          width: 40px;
          height: 40px;
        }

        .top-item-text {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.86px;
          color: rgba(255, 255, 255, 1);
          margin: 0 7px 0 8px;
        }

        .top-item-num {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.75px;
          color: rgba(79, 240, 255, 1);
        }

        .top-item-unit {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.75px;
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    .bottom {
      display: flex;
      margin-top: 23px;

      .left {
        width: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn {
          width: 150px;
          height: 32px;
          background: rgba(31, 42, 52, 1);
          border: none;
          box-sizing: border-box;
          border-left: 4px solid rgba(51, 204, 204, 0.5);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.86px;
          color: rgba(51, 204, 204, 1);
          display: flex;
          align-items: center;
          padding-left: 18px;
          margin-bottom: 10px;
          cursor: pointer;
        }
        .active{
          color: rgba(255, 255, 255, 1);
          border-left: 4px solid rgba(255, 255, 255, 0.5);
        }
      }

      .right {
        width: 516px;
      }
    }
  }
}
</style>
