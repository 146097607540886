<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">种植管理</span>
    </div>
    <div class="content">
      <table class="mb-5" cellspaing="0" align="center" width="100%">
        <thead>
          <tr>
            <th>作物名称</th>
            <th>生长阶段</th>
            <th>任务</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="img-box">
              <!-- <img src="https://img.js.design/assets/img/648ea9a601e6fab0e4326585.png#56b934a178729a776a9d88cb8533d4d7"
                alt="" class="table-img"> -->
              <span>黄瓜</span>
            </td>
            <td>
              幼苗期40-80天
            </td>
            <td>
              <span style="color: rgba(242, 36, 36, 1);">病虫防治</span>
            </td>
          </tr>
          <tr>
            <td class="img-box">
              <!-- <img src="https://img.js.design/assets/img/64fb01c25e0b0cd857170621.webp#5eb9314790d253d58090584bd47dc67f"
                alt="" class="table-img"> -->
              <span>西红柿</span>
            </td>
            <td>
              发芽期7-9天
            </td>
            <td>
              <span style="color: rgba(51, 204, 204, 1);">施肥</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="bottom">
        <div class="bottom-item" v-for="(item,index) in data" :key="index">
          <div class="item-title">{{ item.title }}</div>
          <div class="item-text" v-if="item.text=='进行中'" style="color: rgba(51, 204, 204, 1);">{{ item.text }}</div>
          <div class="item-text" v-else>{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data:[
        {
          title:'种植批次：',
          text:'2023年111批种植'
        },
        {
          title:'开始时间：',
          text:'2023-06-07'
        },
        {
          title:'任务状态：',
          text:'进行中'
        },
        {
          title:'操作记录：',
          text:'施肥--通风--除草--浇水--病虫防治'
        }
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;
  margin-bottom: 10px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title.png');
    background-size: cover;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .table-img {
    width: 65px;
    height: 40px;
    border-radius: 8px;
  }

  th {
    padding: 10px 0;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    color: rgba(51, 204, 204, 1);
  }

  td {
    text-align: center;
    padding: 5px 10px;
    font-size: 14px;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
  }

  .mb-5 {
    margin-bottom: 0px;
  }

  // .img-box {
  //   display: flex;
  //   align-items: center;

  //   .table-img {
  //     margin-right: 8px;
  //   }
  // }

  .bottom {
    width: 100%;
    height: 145px;
    background: url('../../assets/img/bottomBg.png') 0 0 no-repeat;
    background-size: cover;
    padding: 27px 0 0 21px;

    .bottom-item {
      display: flex;
      margin-bottom: 11px;

      .item-title {
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        opacity: 0.5;
        margin-right: 8px;
      }

      &:nth-last-child(1){
        margin-bottom: 0px;
      }
    }
  }
}</style>
